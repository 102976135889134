import React from 'react';

import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import sanityImageUrlBuilder from "../../utils/imageUrlBuilder";
import { Link } from '../Link';

const AccordionContent = ({ article }) => {
    const { sanityId, sanityDataset } = useSiteMetadata();
    const urlBuilder = sanityImageUrlBuilder({
        projectId: sanityId,
        dataset: sanityDataset
    });

    const parentPath = article.parentSection?.parentSection
        ? `${article.parentSection?.parentSection.slug.current}/${article.parentSection?.slug.current}`
        : article.parentSection?.slug.current;

    return (
      <Link
        className="rx-advanced-accordion--panel-content-item"
        _id={article._id}
        to={`${parentPath}/${article.slug.current}`}
        data-analytics-event63=""
        data-analytics-event4=""
      >
        <picture className="placeholder">
          <source
            media={"(min-width: 641px)"}
            srcSet={
              urlBuilder
                .image(article._rawHeroImage)
                .auto("format")
                .quality(80)
                .width(200)
                .height(114)
                .format("webp")
                .url() as string
            }
          />
          <img
            src={
              urlBuilder
                .image(article._rawHeroImage)
                .auto("format")
                .quality(80)
                .width(100)
                .height(100)
                .format("webp")
                .url() as string
            }
            alt={article._rawHeroImage.alt}
          />
        </picture>
        {article.headline}
      </Link>
    );
};

export default AccordionContent;