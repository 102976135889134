import React, { useContext } from 'react';
import AccordionContent from './AccordionContent';
import unlimitedStressSweatIcon from "../../images/unlimited-stress-sweat.png";
import unlimitedHeatIcon from "../../images/unlimited-heat.png";
import unlimitedMovementIcon from "../../images/unlimited-movement.png";
import { LocalizedContext } from "../../services/LocalizedContextService";
import Accessibility from '../Accessibility';

const AccordionItem = (
    {
        index,
        title,
        content,
        handleClick,
        isActive,
        backgroundImage }
) => {

    const tranformedTitle = title.toLowerCase();
    const { sanityAccessibility } = useContext(LocalizedContext);
    const { showContent, hideContent } = sanityAccessibility || {};

    return (
        // <div key={title} className={`rx-advanced-accordion--panel`} style={{ backgroundImage: `url('${backgroundImage.image.asset.url}')` }}>
        <div key={title} className={`rx-advanced-accordion--panel`}>
            <button className={`rx-advanced-accordion--panel-handler`} onClick={() => handleClick(index)}>
                {tranformedTitle === "stress sweat" && <img className={`item-${index}`} src={unlimitedStressSweatIcon} width="46" height="55" />}
                {tranformedTitle === "heat" && <img src={unlimitedHeatIcon} width="61" height="55" />}
                {tranformedTitle === "movement" && <img src={unlimitedMovementIcon} width="61" height="55" />}
                <span>{tranformedTitle}</span>
                <span className={`rx-icon rx-icon-arrow ${isActive === index ? 'isActive' : ''}`}></span>
                <Accessibility location={isActive === index ? hideContent : showContent} />
            </button>
            {<div className="rx-advanced-accordion--panel-content" hidden={isActive !== index}>
                {content.map((article: any, index: number) => (
                    <AccordionContent key={index} article={article} />
                ))}
            </div>}
        </div>
    );
};

export default AccordionItem;