import React, { FunctionComponent, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import RichText from "../RichText";
import AccordionItem from "./AccordionItem";
import "./styles.scss";

const Accordion: FunctionComponent = (props) => {

    const { heading, bgColor, _rawDescription, accordionItems } = props;

    const [isActive, setIsActive] = useState(-1);

    const handleClick = (index) => {
        if (isActive === index) {
            setIsActive(-1);
        } else {
            setIsActive(index);
        }
    };

    return (
        <section className={`rx-advanced-accordion ${isActive === 2 ? 'isLastActive' : ''}`} style={{ backgroundColor: bgColor.value }}>
            <Container fluid>
                <Row>
                    <Col sm={12}>
                        <div className="rx-advanced-accordion--header">
                            <h2>
                                <span>{heading.split('-')[0]}</span>
                                <span className="rx-theme-unlimited">{heading.split('-')[1]}</span>
                                <span>{heading.split('-')[2]}</span>
                            </h2>
                            <div className="rx-advanced-accordion--copy">
                                <RichText data={_rawDescription}></RichText>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        {accordionItems.map((item, index: number) => {
                            return (
                                <AccordionItem
                                    key={index}
                                    backgroundImage={item._rawBackgroundImage}
                                    index={index}
                                    title={item.title}
                                    content={item.content}
                                    handleClick={handleClick}
                                    isActive={isActive} />
                            )
                        })}
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Accordion;
